import React from "react";
import "./styles/Asesoria.css";
const Asesoria = () => {
  return (
    <div className="">
      <a
        href="https://api.whatsapp.com/send?phone=573103907980&text=¡Hola!%20estoy%20interesado%20en%20los%20productos%20de%20House%20Deco.%20Me%20puedes%20brindar%20mayor%20información🏠"
        className="text-white btn-asesoria fs-4 text-decoration-none"
        target="_blank"
        rel="noreferrer"
      >
      Asesoría Gratuita
      </a>
    </div>
  );
};

export default Asesoria;
